import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import VideoTree from "./components/VideoTree";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <Router>
      <Routes>
        {/* 登录页面 */}
        <Route path="/login" element={<Login />} />
        {/* 受保护的主页路由 */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <VideoTree />
            </ProtectedRoute>
          }
        />

        {/* 默认重定向到 /login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;