import React, { useEffect, useState, useRef, memo } from 'react';
import { useNavigate } from "react-router-dom";
import { Tree, Modal, Typography, Spin, Alert, Input, List, Select } from 'antd';
import { TransitionGroup, CSSTransition } from 'react-transition-group'; // 引入动画组件
import { Card, CardContent, Typography as MuiTypography, CircularProgress, duration } from '@mui/material'; // 引入Material UI组件
import { SearchOutlined, DownOutlined, SettingOutlined } from '@ant-design/icons';
import { LazyLoadImage, PlaceholderImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from 'styled-components';
import axios from 'axios';
import { MinimalSelect, StyledSearch } from './StyledComponents';


const MemoizedTree = memo(Tree);
const { Option } = Select;
const { Paragraph } = Typography;

// 为每个搜索结果的 id 分配颜色
const colors = ["#6199FF", "#9261ff", "#eb5680", "#f9ce4b", "#DF8360", "#218F8D", "#D6A1A9", "#A3937C", "#077DAD", "#80BD97"]


const transformData = (nodes) => {
    return nodes.map(node => ({
        title: node.title,
        key: node.id,
        description: node.description,
        filePath: node.filePath,
        start: node.start,
        end: node.end,
        duration: node.duration,
        indices: node.indices,  // 节点包含的 indices
        children: node.children ? transformData(node.children) : []
    }));
};

const VideoTree = () => {
    const [treeData, setTreeData] = useState([]);
    const [originalTreeData, setOriginalTreeData] = useState([]); // 用于存储原始树状数据
    const [searchResults, setSearchResults] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [highlightedNodes, setHighlightedNodes] = useState({}); // 用于存储高亮节点及其颜色
    const [frameCache, setFrameCache] = useState({}); // 封面缓存
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false); // 搜索加载状态
    const [error, setError] = useState(null);
    const [dropdownValue, setDropdownValue] = useState('一代宗师');  // Dropdown selected value
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [imageList, setImageList] = useState([]);  // 用于存储图片列表
    const [isSearchResult, setIsSearchResult] = useState(false);  // 用于区分是否是搜索结果
    const [baseUrl, setBaseUrl] = useState('');
    // const [config, setConfig] = useState(null);
    const videoRef = useRef(null);

    // const apiHost = process.env.REACT_APP_API_HOST || 'localhost';
    // const apiPort = process.env.REACT_APP_API_PORT || '7013';
    // const baseUrl = `${apiHost}:${apiPort}`;

    // const navigate = useNavigate();

    // const handleLogout = () => {
    //   localStorage.removeItem("isAuthenticated"); // 清除登录状态
    //   navigate("/login"); // 跳转到登录页面
    // };
    
    // 通过 fetch 获取 public 目录下的 config.json 文件
    useEffect(() => {
      fetch('/config.json')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setBaseUrl(data.API_BASE_URL);
        })
        .catch((error) => {
          console.error('Error loading config:', error);
        });
    }, []);

    // 加载完整树状数据
    useEffect(() => {
        console.log('Fetching tree data...', `/api/tree?videoName=${dropdownValue}`);
        axios.get(`/api/tree?videoName=${dropdownValue}`)
            .then(response => {
                // console.log('Tree data fetched:', response.data);
                const transformedData = transformData([response.data]); // 根节点是对象，需要放入数组中
                setOriginalTreeData(transformedData); // 保存原始树数据
                // console.log('Transformed tree data:', transformedData);
                setTreeData(transformedData);
                setLoading(false);
            })
            .catch(err => {
                setError('无法加载树状数据');
                setLoading(false);
            });
        // 设置 body overflow 为 hidden，禁止页面滚动
        document.body.style.overflow = 'hidden';

        // 在组件卸载时恢复 overflow
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [baseUrl]);

    useEffect(() => {
        if (treeData.length > 0) {
            const allKeys = getAllKeys(treeData);
            setExpandedKeys(allKeys);
        }
    }, [treeData]);

    // 当视频播放时，自动从 start 开始，并在 end 时间暂停
    useEffect(() => {
        if (selectedVideo && videoRef.current) {
            const video = videoRef.current;
            video.src = selectedVideo.filePath; // 更新视频源

            // 等待视频加载完成后再设置播放时间
            video.onloadedmetadata = () => {
                video.currentTime = convertTimeToSeconds(selectedVideo.start);
                video.play();
            };

            // 监听时间更新事件，当视频播放到 end 时暂停
            const handleTimeUpdate = () => {
                if (video.currentTime >= convertTimeToSeconds(selectedVideo.end)) {
                    video.pause();
                }
            };

            video.addEventListener('timeupdate', handleTimeUpdate);

            // 清除事件监听器
            return () => {
                video.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, [selectedVideo]);


    const getAllKeys = (nodes) => {
        let keys = [];
        nodes.forEach(node => {
            keys.push(node.key);
            if (node.children) {
                keys = keys.concat(getAllKeys(node.children));
            }
        });
        return keys;
    };

    // 搜索功能，查找搜索结果中的 id，并为树结构中的节点加上颜色标记
    const handleSearch = (value) => {
        // console.log('Start search: ', value)
        // 重置高亮节点和树结构
        setHighlightedNodes({}); // 清空高亮节点
        const clearedTreeData = clearTreeDataHighlights([...originalTreeData]); // 使用深拷贝还原树结构
        setTreeData(clearedTreeData); // 清除标记后的树结构
            
        // 开始搜索，设置加载状态为 true
        setSearchLoading(true); 
        console.log('Search:', value, 'Dropdown:', dropdownValue);
        axios.get(`/api/search?query=${value}&filter=${dropdownValue}`)
            .then(response => {
                const searchResults = response.data; // 假设返回的搜索结果有 ids 列表
                setSearchResults(searchResults);
                
                // 找到树结构中与搜索结果匹配的节点
                const newHighlightedNodes = {};
                searchResults.forEach((result, index) => {
                    // 直接将 result.id 作为 key 存储对应颜色
                    newHighlightedNodes[result.id] = colors[index % colors.length];
                    // 递归找到匹配的树节点
                    findAndHighlightNodes(clearedTreeData, result.id, colors[index % colors.length], newHighlightedNodes);
                    // console.log(result.id, newHighlightedNodes)
                });
                
                setHighlightedNodes(newHighlightedNodes);
                setTreeData(updateTreeDataWithHighlights(clearedTreeData, newHighlightedNodes)); // 更新树结构，递归标记
                setSearchLoading(false); // 搜索完成，设置加载状态为 false
                console.log('search results: ', searchResults.length)
            })
            .catch(err => {
                setError('搜索失败');
                setSearchLoading(false); // 搜索失败时也设置为 false
            });
    };

    // 递归清除树结构中的颜色标记
    const clearTreeDataHighlights = (nodes) => {
        return nodes.map(node => ({
            ...node,
            title: typeof node.title === 'string' ? node.title : node.title.props.children[0], // 确保 title 是字符串
            children: node.children ? clearTreeDataHighlights(node.children) : []
        }));
    };

    // 递归遍历树结构，检查节点的 indices 是否包含搜索结果中的 id，并为其添加颜色标记
    const findAndHighlightNodes = (nodes, targetId, color, highlightedNodes) => {
        nodes.forEach(node => {
            if (node.indices && node.indices.includes(targetId)) {
                if (!highlightedNodes[node.key]) {
                    highlightedNodes[node.key] = [];
                }
                highlightedNodes[node.key].push(color); // 添加颜色标记
            }

            if (node.children) {
                findAndHighlightNodes(node.children, targetId, color, highlightedNodes); // 递归处理子节点
            }
        });
    };

    // 递归更新树结构，给每个匹配的节点添加颜色标记
    const updateTreeDataWithHighlights = (nodes, highlightedNodes) => {
        return nodes.map(node => {
            const newTitle = (
                <span>
                    {typeof node.title === 'string' ? node.title : node.title.props.children[0]}
                    {/* 如果有高亮节点，显示对应颜色的五角星 */}
                    {highlightedNodes[node.key] && highlightedNodes[node.key].map((color, idx) => (
                        <span key={idx} style={{ color, marginLeft: 5 }}>###</span>
                    ))}
                </span>
            );

            return {
                ...node,
                title: newTitle, // 更新节点 title
                children: node.children ? updateTreeDataWithHighlights(node.children, highlightedNodes) : [] // 递归更新子节点
            };
        });
    };

    // 获取对应的颜色标记
    const getColorForResult = (id) => {
        return highlightedNodes[id] || null; // 从 highlightedNodes 中查找颜色
    };

    // 获取图片列表
    const fetchImages = (title) => {
        axios.get(`/api/images?title=${title}`)
            .then(response => {
                setImageList(response.data);
            })
            .catch(err => {
                console.error('无法获取图片:', err);
            });
    };

    // const onSelect = (selectedKeys, info) => {
    //     if (info.selected && info.node) {
    //         const { title, description, filePath, start, end } = info.node;
    //         console.log('Selected node:', { title, description, filePath, start, end });
    //         setSelectedVideo({ title, description, filePath, start, end });
    //     }
    // };

    // 点击树节点播放视频
    const onSelect = (selectedKeys, info) => {
        if (info.selected && info.node) {
            // const { title, description, filePath, start, end } = info.node;
            // console.log('Selected node:', { title, description, filePath, start, end });
            setIsSearchResult(false);  // 标记为非搜索结果
            setSelectedVideo(info.node);
            // setSelectedVideo({ title, description, filePath, start, end });
            setImageList([]);  // 清空图片列表
        }
    };

    // 点击树节点播放视频
    const playVideo = (video) => {
        setSelectedVideo(video);
    };

    // 点击搜索结果播放视频
    const playVideoFromSearch = (video) => {
        setIsSearchResult(true);  // 标记为搜索结果
        setSelectedVideo(video);
        // console.log(video.title)
        fetchImages(video.title);  // 仅在搜索结果中获取图片
    };

    // 转换时间格式 (00:00:00.000) 为秒
    const convertTimeToSeconds = (time) => {
        const parts = time.split(':');
        const seconds = parseFloat(parts[2]);
        const minutes = parseInt(parts[1], 10) * 60;
        const hours = parseInt(parts[0], 10) * 3600;
        return hours + minutes + seconds;
    };

    // 提取视频某一时间点的帧作为封面
    const extractFrame = (fileName, timeInSeconds, callback) => {
        const video = document.createElement('video');
        video.src = `${fileName}`; // 设置视频源路径
        if (timeInSeconds === 0) {
            timeInSeconds = 1;
        }
        video.currentTime = timeInSeconds; // 设置播放时间点
        video.crossOrigin = "anonymous";  // 确保跨域视频也能操作

        video.addEventListener('loadeddata', () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageDataUrl = canvas.toDataURL();  // 提取帧为图片数据 URL
            // console.log('封面', imageDataUrl)
            callback(imageDataUrl);  // 调用回调函数，将图片传递出去
        });
    };

    // 将时间转为秒
    const parseTime = (timeString) => {
        const parts = timeString.split(':').map(parseFloat);
        return parts[0] * 3600 + parts[1] * 60 + parts[2];
    };

    // 获取封面图（如果已经缓存，则返回缓存，否则提取帧）
    const getFrameUrl = (id, fileName, start) => {
        if (frameCache[fileName+'-'+id]) {
            return frameCache[fileName+'-'+id]; // 返回缓存的封面图
        }

        const timeInSeconds = parseTime(start);
        
        // 创建局部缓存
        const cachedFileName = fileName+'-'+id; // 局部缓存 filename 确保异步调用不会混乱
        
        extractFrame(fileName, timeInSeconds, (imageUrl) => {
            setFrameCache(prevCache => ({ ...prevCache, [cachedFileName]: imageUrl })); // 使用局部的 fileName 更新缓存
        });

        return 'loading-placeholder.jpg'; // 返回占位图
    };

    const extractCover = async (fileName, callback) => {
        try {
            const response = await axios.get(`/api/cover`, {
                params: { fileName },
            });
            const imageUrl = response.data.imageUrl; // Assuming the API returns { imageUrl: 'url-to-frame' }
            callback(imageUrl);
        } catch (error) {
            console.error('Error fetching frame from API:', error);
        }
    };

    const getCoverUrl = (fileName) => {
        if (frameCache[fileName]) {
            return frameCache[fileName]; // 返回缓存的封面图
        }
        
        // 创建局部缓存
        const cachedFileName = fileName; // 局部缓存 filename 确保异步调用不会混乱
        
        extractCover(fileName, (imageUrl) => {
            setFrameCache(prevCache => ({ ...prevCache, [cachedFileName]: imageUrl })); // 使用局部的 fileName 更新缓存
        });

        return 'loading-placeholder.jpg'; // 返回占位图
    };

    // 关闭弹窗时清空状态
    const handleModalClose = () => {
        if (videoRef.current) {
            videoRef.current.pause(); // 暂停视频播放
        }
        setSelectedVideo(null);
        setImageList([]);
    };

    const handleDropdownChange = (value) => {
        setDropdownValue(value);  // Update the selected dropdown value
        setSearchResults([]);
        // 假设下拉框选择的数据会成为查询的参数
        axios.get(`/api/tree`, {
            params: { videoName: value }  // 将下拉框选择的值传递给后端
        }).then(response => {
            const transformedData = transformData([response.data]);
            setOriginalTreeData(transformedData);
            setTreeData(transformedData);
        }).catch(err => {
            setError('无法加载数据');
        });
    };
    

    // 返回页面HTML
    if (loading) {
        return <Spin tip="加载中..." />;
    }
    if (error) return <Alert message="错误" description={error} type="error" showIcon />;

    return (
        <div style={{ padding: '20px', backgroundColor: '#fff', maxWidth: '100vw', overflowY: 'auto', height: '100vh', padding: '0px 100px 0px 100px'}}> {/* 修改页面背景颜色，增加整体一致性 */}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '150px', marginBottom: '20px' }}> {/* 标题布局上下左右居中 */}
                <h1 style={{ fontSize: '6rem', fontWeight: 'bold', letterSpacing: '2px', color: '#7D7D7D', margin: 0 }}> {/* 大标题科技感，类似于Google风格 */}
                    <span style={{ color: '#4285f4' }}>o</span>
                    <span style={{ color: '#ea4335' }}>h</span>
                    <span style={{ color: '#fbbc05' }}>h</span>
                    <span style={{ color: '#34a853' }}>o</span>
                </h1>
            </div>    

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0 60px 0' }}> {/* 搜索框布局居中 */}
                <StyledSearch
                    placeholder="搜索视频"
                    // enterButton={<SearchOutlined style={{ paddingBlock: 10, maxWidth: '100px', width: '100%', maxHeight: '1000px', borderRadius: '30px', fontSize: '20px' }} />} /* 使用Ant Design的搜索图标 */
                    onSearch={handleSearch}
                    size="large"
                    style={{
                        maxWidth: '1000px',
                        // width: '100%',
                        // height: '60px',  // Adjust the height of the input
                        // borderRadius: '30px',  // Adjust the border radius for input
                        // padding: '20px',  // Ensure no extra padding is applied
                        // overflow: 'hidden',  // Hide overflow for rounded corners
                    }}
                />
                <MinimalSelect defaultValue="一代宗师" dropdownMatchSelectWidth={false} style={{ width: '200px', textAlign: 'center', marginRight: '0px' }} onChange={handleDropdownChange}>
                        {/* PerfectPlanet, HotShots, Grandmaster */}
                        <Option value="一代宗师">一代宗师</Option>
                        <Option value="第二十条">第二十条</Option>
                        <Option value="三大队">三大队</Option>
                        <Option value="完美星球">完美星球</Option>
                        <Option value="反斗神鹰">反斗神鹰</Option>
                </MinimalSelect>
            </div>

            {/* 搜索加载状态 */}
            {searchLoading && (
                <div style={{ textAlign: 'center', marginBottom: '100px' }}> {/* 搜索加载状态，居中显示 */}
                    <CircularProgress style={{ color: '#A3A3A3'}} /> {/* 使用Material UI的加载圆圈，莫兰迪色系 */}
                </div>
            )}

            {!searchLoading && searchResults.length > 0 && (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', gap: '20px', marginBottom: '60px' }}> {/* 使用更大的网格布局展示搜索结果 */}
                    {searchResults.map((item) => {
                        // const frameUrl = getFrameUrl(item.id, item.fileName, item.start);
                        const frameUrl = getCoverUrl(item.title);
                        if (!frameUrl) return null;
                        return (
                        <Card key={item.id} onClick={() => playVideoFromSearch(item)} style={{ cursor: 'pointer', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#F5F5F5', borderRadius: '10px' }}> {/* 使用Material UI的Card组件，莫兰迪色系 */}                            
                            <LazyLoadImage
                                // src={getFrameUrl(item.id, item.fileName, item.start)}
                                src={getCoverUrl(item.title)}
                                alt="视频封面"
                                style={{ width: '100%', height: 'auto'}}
                                loading="lazy"
                                effect="blur"
                                PlaceholderSrc={PlaceholderImage}
                            /> {/* 显示更大的封面图 */}
                            <CardContent>
                                <MuiTypography variant="h6" style={{ color: getColorForResult(item.id) }}>{item.title}</MuiTypography> {/* 使用不同颜色显示标题 */}
                                <MuiTypography variant="body2" style={{ color: '#777', marginTop: '10px' }}>{item.description}{item.duration}</MuiTypography> {/* 显示描述信息 */}
                            </CardContent>
                        </Card>
                    );
                    })}
                </div>
            )}

            {/* 视频树状结构 */}
            <Tree
                onSelect={onSelect}
                treeData={treeData}
                defaultExpandAll
                expandedKeys={expandedKeys} // 使用受控展开状态
                onExpand={(keys) => setExpandedKeys(keys)} // 更新展开状态
                showLine
                switcherIcon={<DownOutlined />}
                style={{ overflow: 'auto', backgroundColor: '#F5F5F5', padding: '10px', marginBottom: '50px' , borderRadius: '8px', border: 'none' }} //maxHeight: '100vh'
            />

            {/* 视频播放弹窗 */}
            <Modal
                open={selectedVideo !== null}
                title={selectedVideo?.title}
                footer={null}
                onCancel={handleModalClose}
                width={isSearchResult ? '80%' : '60%'}  // 如果是搜索结果，弹窗更宽一些
            >
                {selectedVideo && (
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        {/* 视频区域 */}
                        {/* <div style={{ flexBasis: '100%'}}> */}
                        <div style={{ flexBasis: isSearchResult ? '70%' : '100%', height: '100%' }}>
                            <video ref={videoRef} width="100%" controls>
                                <source src={`${selectedVideo.filePath}`} type="video/mp4" />
                                您的浏览器不支持视频标签。
                            </video>
                            <Paragraph>{selectedVideo.description}</Paragraph>
                        </div>

                        {/* 图片展示区域，仅在搜索结果时展示 */}
                        {isSearchResult && imageList.length > 0 && (
                            <div style={{ flexBasis: '30%', marginLeft: '2%', display: 'flex', flexDirection: 'column', overflowY: 'auto', maxHeight: '60vh' }}>
                                {imageList.map((imgUrl, index) => (
                                    <img key={index} src={imgUrl} alt={`Related Image ${index}`} style={{ width: '100%', height: 'auto', marginBottom: '10px' }} />
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {/* {selectedVideo && (
                    <>
                        <video ref={videoRef} width="1000px" controls>
                            <source src={`${selectedVideo.filePath}`} type="video/mp4" />
                            您的浏览器不支持视频标签。
                        </video>
                        <Paragraph>{selectedVideo.description}</Paragraph>
                        
                        {isSearchResult && imageList.length > 0 && (
                            <div style={{ display: 'flex', overflowX: 'auto', marginTop: '20px' }}>
                                {imageList.map((imgUrl, index) => (
                                    <img key={index} src={imgUrl} alt={`Related Image ${index}`} style={{ width: '200px', height: '100px', marginRight: '10px' }} />
                                ))}
                            </div>
                        )}
                    </>
                )} */}
            </Modal>
        </div>
    );
};


export default VideoTree;