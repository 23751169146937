import { Tree, Modal, Typography, Spin, Alert, Input, List, Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;
const { Search } = Input;

export const StyledSearch = styled(Search)`
    .ant-input {
        height: 50px !important;
        border-radius: 30px !important;
        padding-left: 20px !important;
    }
    .ant-input-group-addon {
        display: none !important;
    }
`;

export const MinimalSelect = styled(Select)`
  .ant-select-selector {
    border: none !important;  // Remove the border
    background-color: transparent !important;  // Transparent background
    box-shadow: none !important;  // No shadow
    padding: 0 !important;  // Remove padding
  }
  .ant-select-selection-item {
    text-decoration: underline;  // Add underline
    font-family: 'Arial', sans-serif;  // Use a clean, modern font
    color: #7d7f82;  // Use a Morandi color (grayish-blue tone)
  }
  .ant-select-dropdown {
    font-family: 'Arial', sans-serif;  // Modern font in the dropdown
    text-decoration: underline;  // Add underline to options
    color: #7d7f82;  // Morandi color tone for dropdown options
    background-color: #f0f0f0;  // Light background for contrast
  }
  .ant-select-arrow {
    display: none;  // Hide the default arrow
  }
`;
